(function() {
    document.addEventListener("DOMContentLoaded", function() {
        // Bind the event listener to the navigation toggle button.
        const navToggle = document.querySelector('button.navbar-toggler');
        const body = document.body;

        if (navToggle) {
            navToggle.addEventListener('click', function(e) {
                e.preventDefault();
                const mainNavContainer = document.getElementById('qvauMainNav');

                if (!mainNavContainer) {
                    return;
                }

                if (mainNavContainer.classList.contains('active')) {
                    mainNavContainer.classList.remove('active');
                    body.classList.remove('mobile-nav-active');
                    navToggle.classList.remove('active');
                    navToggle.setAttribute('aria-expanded', 'false');
                }
                else {
                    mainNavContainer.classList.add('active');
                    body.classList.add('mobile-nav-active');
                    navToggle.classList.add('active');
                    navToggle.setAttribute('aria-expanded', 'true');
                }
            });
        }

        function resetDropDownMenus() {
            document.querySelectorAll('.nav-link.has-children.show').forEach((item) => {
                toggleMenuDropDown(item);
            });
        }

        function toggleMenuDropDown(element) {
            const dropdown = element.nextElementSibling;

            if (element.classList.contains('show')) {
                element.classList.remove('show');
                element.setAttribute('aria-expanded', 'false');
                dropdown.classList.remove('show');
            }
            else {
                // Reset any active menus.
                element.classList.add('show');
                element.setAttribute('aria-expanded', 'true');
                dropdown.classList.add('show');
            }
        }

        const dropdownToggles = document.querySelectorAll('.nav-link.has-children');
        dropdownToggles.forEach((item) => {
            // Prevent the parent link from being accessed.
            item.addEventListener('click', (e) => {
                e.preventDefault();
                toggleMenuDropDown(item);
            });

            item.addEventListener('keyup', (e) => {
                if (e.keyCode === 13) {
                    e.preventDefault();
                    item.click();
                }
            })
        });

        document.addEventListener('click', (e) => {
           if (!e.target.closest('.nav-link.has-children')) {
               resetDropDownMenus();
           }
        });
    });
})();
(function() {

    // Simply adds a class to the body to prevent transitions on document resize.
    let resizeTimer;
    window.addEventListener("resize", () => {
      document.body.classList.add("resize-transitions-stopper");
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        document.body.classList.remove("resize-transitions-stopper");
      }, 400);
    });

})();